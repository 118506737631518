import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import Icon from '../images/sync-bionics-icon.svg'

import HoldingHandsSection from '../components/HoldingHandsSection'


const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Container fluid>
      <Row>
        <Col className="zero-padding-col" sm={12} md={6}>
        <Img 
        className="home-header"
        fluid={data.homeBackground.childImageSharp.fluid}
        alt="Sync Bionics"
      />
        </Col>
        <Col className="zero-padding-col featured-content-container" sm={12} md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
        <div className="featured-content">
        <h1 className="featured-headline">
          Creating technology to improve the lives of prosthesis users
        </h1>
        <div className="btn btn-success btn-sync home-btn"
        >LEARN MORE</div>
      </div>
        </Col>
      </Row>
            
    </Container>
    <Container fluid
      style={{ 
        backgroundColor: '#36762F'
      }}
    >
      <Row>
        <Col>
        <img src={Icon} className="homepage-icon" 
            />  
        </Col>
      </Row>
      
      <Container style={{
        backgroundColor: '#36762F'
      }}>
      <Row>
        <Col className="d-flex justify-content-center"> 
     
          <div className="green-box">
          <h2 style={{
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            marginBottom: '2rem'

            }}>
              Take control of your prosthesis with Sync Bionics' advanced implantable systems
            </h2>
            <p style={{
              textAlign: 'center',
              color: 'white'
            }}>
            Sync Bionics has developed the Wave to provide prosthesis users with unparalleled control of their prosthetic limb. No more performing a series of unintuitive contractions to activate a single movement of your prosthesis. With the Wave you will be able to intuitively control multiple joints of your prosthesis at the same time.
            </p>
            </div>
        </Col>
      </Row>
      </Container>
      </Container>

      <Container fluid>
        <HoldingHandsSection>
        
          <Row style={{
            height: '100%',
          }}>
            <Col md={{ span: 3, offset: 2 }} style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <div className="block-copy">
                <h3>Intuitive, Multi-Joint Prosthesis Control</h3>
                <p className="white-copy">The Wave is the first fully implantable myoelectric system for upper limb prosthesis control. Recording from multiple muscles in the residual limb, the Wave provides higher quality prosthesis control signals than any other system on the market.</p>
              </div>
            </Col>
          </Row>
        
        </HoldingHandsSection>
 </Container>

 <Container fluid>
    <Row>
      <Col sm={6} style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <div className="featured-content block-copy">
        <h3 className="green-title">
          Creating technology to improve the lives of prosthesis users
        </h3>
        <p>The Wave myoelectric training software provides advanced new ways to interact with the prosthesis. Our training modules allow users to perform training exercises with or without their prosthesis either in the clinic or at home.</p>
      </div>
      </Col>
      <Col style={{
        height: '100%'
      }} >
      <Img 
        className="wave"
        key="waveImg"
        fluid={data.wave.childImageSharp.fluid}
        alt="Sync Bionics"
      /> 
      </Col>
    </Row>
 </Container>
  </Layout>
)

export const query = graphql`
  query {
    homeBackground: file(relativePath:{eq:"header-image.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wave: file(relativePath:{eq:"022019-MIRA-6-whitebg.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage